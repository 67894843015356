/* eslint-disable complexity */
export const formatAddressReturnToData = (address) => ({
  city: address.city,
  firstname: address.firstname,
  lastname: address.lastname,
  postcode: address.postcode,
  region: address.region.code,
  street: address.street[0],
  apartment: address.street[1],
  telephone: address.telephone,
  country_code: address.country.code,
});

export const addressFromApiToForm = (address) => {
  // eslint-disable-next-line no-underscore-dangle
  if (address?.__typename) {
    return {
      firstname: address.firstname,
      lastname: address.lastname,
      street: Array.isArray(address.street) ? address.street[0] : '',
      apartment: Array.isArray(address.street) ? address.street[1] || '' : '',
      city: address.city,
      region: address?.region?.code || address?.region?.region,
      country_code: address?.country?.code || address.country_code,
      postcode: address.postcode,
      telephone: address.telephone,
    };
  }

  return address;
};

export const addressFormToApi = (address) => {
  if (address.default_shipping) delete address.default_shipping;
  if (address.default_billing) delete address.default_billing;
  return { ...address, region: address.region.region_code };
};

// check if the address saved in cart has the exact same values as an address from the customer
export const compareAddresses = (addressFromCart, customerAddress) => {
  const addressesCommonFields = ['firstname', 'lastname', 'city', 'postcode', 'telephone'];
  let validCommonFields = true;
  addressesCommonFields.forEach((field) => {
    if (addressFromCart?.[field] !== customerAddress?.[field]) {
      validCommonFields = false;
    }
  });
  const cartStreet = addressFromCart?.street?.slice()?.sort();
  const customerStreet = customerAddress?.street?.slice()?.sort();
  const sameStreet = cartStreet !== undefined && customerStreet !== undefined ? JSON.stringify(cartStreet) === JSON.stringify(customerStreet) : false;
  return (
    sameStreet &&
    validCommonFields &&
    addressFromCart?.country?.code === customerAddress?.country_code &&
    addressFromCart?.region?.code === customerAddress?.region?.region_code
  );
};

// Check if all feilds in the address are filled
export const validateAddress = (address) => {
  return Object.values(address).every(Boolean);
};
